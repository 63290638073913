import React, { useEffect, useState } from "react"
import { Space, Table, Popover } from "antd"
import Button from "../../Button"
import optionIcon from "../../../assets/images/options-white.svg"
import pencilIcon from "../../../assets/images/writer.svg"
import pencilActiveIcon from "../../../assets/images/writer-blue.svg"
import flagActiveIcon from "../../../assets/images/flag-blue.svg"
import flagIcon from "../../../assets/images/flag.svg"
import starIcon from "../../../assets/images/star.svg"
import deleteIcon from "../../../assets/images/delete.svg"
import deleteActiveIcon from "../../../assets/images/delete-blue.svg"
import "./style.scss"
import {
  adminChaptersDelete,
  getAdminChaptersData,
  setConcatChapterss
} from "../../../pages/AdminScreens/Chapters/redux/action"
import { connect } from "react-redux"
import { useVT } from "virtualizedtableforantd4"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import {
  setUpdateNovelID
} from "../../../pages/AuthScreens/Login/redux/action"
import { history } from "../../../reduxStore/store"
import toast from "react-hot-toast"
const ChaptersTable = props => {
  const {
    chapterInfo,
    currentPage,
    concatData,
    setShow,
    setUpdateData,
    setModal,
    getAdminChaptersData,
    isLoading,
    setConcatChapterss,
    setUpdateNovelID,
    requesting
  } = props

  const [tooltip, setTooltip] = useState(false)
  const [activeClass, setActiveClass] = useState(false)
  const [concatUser, setConcatUser] = useState([])

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    getAdminChaptersData("", "", 1)
  }, [])

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current:currentPage?.current_page,
        total: currentPage?.total_objects,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
    setConcatUser(chapterInfo)
  }, [currentPage])


  const text = <span></span>

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: text => <a>ID{text}</a>
    },
    {
      title: "Chapter Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <div className="grt-main">
            <div className="grt-table-row">{item.name ? item.name : "---"}</div>
          </div>
      )
    },
    {
      title: "Writer",
      dataIndex: "writer_name",
      key: "writer_name",
      render: (_, item) => {
        return (
          <p className="email mb-0" >
            {item.writer_name ? item.writer_name : "---"}
          </p>
        )
      }
    },
    {
      title: "Editor",
      dataIndex: "editor_name",
      key: "editor_name",
      render: (_, item) => (
        <p className="email mb-0" >
          {item.editor_name ? item.editor_name : "---"}
        </p>
      )
    },
    {
      title: "Producer",
      dataIndex: "producer_name",
      key: "producer_name",
      render: (_, item) => (
        <p className="email mb-0" >
          {item.producer_name ? item.producer_name : "---"}
        </p>
      )
    },
    {
      title: "Token Earn",
      key: "token_earn",
      dataIndex: "token_earn",
      render: (_, item) => {
        return (
          <p className="email mb-0">
          {item.token_earn ? item.token_earn : "---"}
          </p>
        )
      }
    },
    {
      title: " ",
      render: (_, value) => (
        <Space
          size="middle"
          className="phone-details user-phone-details"
          key={value.id}
        >
          <Popover
            placement="left"
            title={text}
            content={popoverContent(value)}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}
          >
            <div
              className={`options ${tooltip === value.novel_id && activeClass === value.novel_id ? "active" : ""
                }`}
              onClick={() => {
                onAction(value.novel_id)
              }}
            >
              <img src={optionIcon} alt="" key={value.novel_id} />
            </div>
          </Popover>
        </Space>
      )
    }
  ]

  const onEdit = val => {
    setUpdateData(val)
    setShow(true)
    setModal(true)
  }

  const onAction = id => {
    setActiveClass(id)
    setTooltip(id)
  }
  const popoverContent = value => {
    return (
      tooltip === value.novel_id && (
        <div className="option-items">
          <div className="item" onClick={() => {
            if(value?.novel_id > 0){
              setUpdateNovelID(value?.novel_id)
              history.push(`/admin/update_novel/${value?.novel_id}`)
            }else{
              toast.error("Chapter created wrongly and cant update.", {
                id: "card fields"
              })
            }
          }}>
            <img src={pencilIcon} className="img" alt="" />
            <img src={pencilActiveIcon} className="active-img" alt="" />
            <div className="text">Edit</div>
          </div>
        </div>
      )
    )
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    console.log(pagination);
    // `dataSource` is useless since `pageSize` changed
    if (pagination.current !== tableParams?.pagination?.current) {
      setConcatChapterss(true)
      getAdminChaptersData('', "", pagination.current)
    }
  };

  if (requesting || !chapterInfo) {
    return (
      <>
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
          <Table
            rowKey="key"
            pagination={false}
            dataSource={[...Array(10)].map((_, index) => ({
              key: `key${index}`
            }))}
            columns={columns.map(column => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return <Skeleton width="40%" height='10px' />
                }
              }
            })}
          />

        </SkeletonTheme>
      </>
    )
  } else {
    return (
      <Table
        scroll={{
          scrollToFirstRowOnChange: false,
          y: 600,
          x: 1000
        }}
        columns={columns}
        dataSource={concatUser}
        loading={requesting}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
    )
  }
}
const mapStateToProps = state => ({
  currentPage: state.adminChapters.currentPage,
  chapterInfo: state.adminChapters.chapterInfo,
  concatData: state.adminChapters.concatChapterss,
  requesting: state.adminChapters.requesting
})

const mapDispatchToProps = dispatch => ({
  adminChaptersDelete: data => dispatch(adminChaptersDelete(data)),
  getAdminChaptersData: (data, search, page) => dispatch(getAdminChaptersData(data, search, page)),
  setConcatChapterss: data => dispatch(setConcatChapterss(data)),
  setUpdateNovelID: data => dispatch(setUpdateNovelID(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChaptersTable)
